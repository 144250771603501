<template>
  <div class="client-list">
    <div class="header">
      <h1>My devices</h1>
      <!-- <button v-if="!addMode" @click="addMode=true">Add a device</button> -->
    </div>

    <form @submit.prevent="onSubmit">
      <input v-if="addMode" type="text" v-model="newName" placeholder="Nombre del dispositivo" />
      <button v-if="addMode">Guardar</button>
    </form>

    <ul class="clients">
      <li>
        <div class="client client-header">
          <span class="name">Device name</span>
          <span class="connection-status">Connection status</span>
          <span class="controls">Action</span>
        </div>
      </li>
      <li v-for="client in clients" :key="client.id">
        <client-item
          :client="client"
          @clientNameEdited="editClientName(client.id, $event)"
          @publishRequested="publishMessage(client.id, $event)"
        ></client-item>
      </li>
    </ul>
  </div>
</template>

<style>
.client-list .header {
  background-color: #1e9bed;
  padding: 16px;
  display: flex;
  justify-content: space-between;
}

.client-list h1 {
  color: #fff;
}

.client-list .clients {
  padding: 0;
  margin: 0;
}

.client-list .client-header {
  background-color: #c8f2ff;
  font-weight: bold;
}

.client-list .clients li {
  list-style: none;
  font-size: 0.9em;
}

.client-list .client {
  text-align: left;
  border-top: 1px dotted #bbb;
  padding: 16px;
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
}

.client-list .connection-status {
  display: inline-block;
  margin-right: 8px;
  margin-left: auto;
  width: 80px;
  text-align: center;
}

.client-list .controls,
.client-list .controls button {
  width: 60px;
  text-align: center;
}

</style>

<script>

import ClientItem from './ClientItem.vue';
import { CLIENTS_ADD, CLIENTS_EDIT, CLIENTS_REQUEST, PUBLISH_MESSAGE } from '../store/mutation-types';

export default {
  components: {
    ClientItem
  },
  computed: {
    clients () {
      return this.$store.state.clients
    }
  },
  data() {
    return {
      addMode: false,
      newName: ''
    }
  },
  mounted () {
    this.$store.dispatch(CLIENTS_REQUEST)
  },
  methods: {
    editClientName(clientId, newName) {
      this.$store.dispatch(CLIENTS_EDIT, { id: clientId, name: newName });
    },
    publishMessage(clientId) {
      this.$store.dispatch(PUBLISH_MESSAGE, { id: clientId });
    },
    onSubmit() {
      this.$store.dispatch(CLIENTS_ADD, { name: this.newName });
    }
  }
};

</script>
