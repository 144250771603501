<template>
  <div class="client">
    <span class="name" v-if="!editMode">{{ client.name }}</span>
    <input v-else type="text" v-model="newName"/>

    <!-- <span v-if="!editMode" @click="toggleEditMode()" class="material-icons md-18 edit-button">edit</span>
    <span v-else @click="save()" class="material-icons md-18 edit-button">done</span>-->

    <span v-if="!client.connected" class="connection-status">OFFLINE</span>
    <span v-if="client.connected" class="connection-status connected">ONLINE</span>

    <div class="controls">
      <button @click="publish()">Toggle</button>
    </div>

    <div v-if="client.status" class="status">
      <span class="item" v-for="(value, name) in client.status" :key="name">
        {{ name }}: {{ value }}
      </span>
    </div>
  </div>
</template>

<style scoped>
.connection-status {
  color: red;
  height: 2em;
  line-height: 2em;
  font-size: 0.8em;
}

.connection-status.connected {
  color: #006400;
}

.status {
  width: 100%;
  font-size: 80%;
}

.status .item {
  padding-right: 8px;
}
</style>

<script>
export default {
  props: [ 'client' ],
  data() {
    return {
      editMode: false,
      newName: this.client.name
    }
  },
  methods: {
    toggleEditMode() {
      this.newName = this.client.name;
      this.editMode = !this.editMode;
    },
    save() {
      this.editMode = !this.editMode;

      if (this.newName !== this.client.name) {
        this.$emit('clientNameEdited', this.newName);
      }
    },
    publish() {
      this.$emit('publishRequested');
    }
  }
};
</script>
