<template>
  <div id="app">
    <p class="nav">
      <router-link to="/">Home</router-link>
      <router-link class="docs" to="/docs">Documentation</router-link>
      <!-- <router-link class="about" to="/about">About</router-link> -->
      <router-link class="logout" v-if="loggedIn" to="/logout">Logout</router-link>
    </p>
    
    <router-view></router-view>
  </div>
</template>

<style>
html, body {
  background-color: #EBF1F4;
  font-family: 'Roboto', sans-serif;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  width: 100%;
  background-color: #fff;
  font-size: 16px;
}

h1 {
  margin: 0;
  color: #006ebb;
}

button {
  display: block;
  margin: 0;
  padding: 2px 4px;
  border: 0;
  background-color: #1e9bed;
  color: #fff;
  border-radius: 3px;
  font-size: 0.8em;
  font-weight: 800;
  height: 2em;
}

.nav {
  background-color: #006ebb;
  display: flex;
  margin: 0;
  padding: 16px;
  font-size: 0.8em;
}

.nav a {
  margin: 0 4px;
  color: #fff;
  font-weight: bold;
  text-decoration: none;
}

.nav .logout {
  margin-left: auto;
}
</style>

<script>
import 'normalize.css';
import WebSocketManager from './services/WebSocketManager';

export default {
  name: 'App',
  computed: {
    loggedIn () {
      return this.$store.state.loggedIn
    }
  },
  mounted: function () {
    WebSocketManager.use(this.$store);

    if (this.$store.state.loggedIn) {
      WebSocketManager.connect();
    }
  }
}
</script>
